<template>
    <AppHeader/>
    <ProductsBanner/>
    <ProductsInfo/>
    <ContactForm/>
    <AppFooter/>
  </template>
  
  <script>
  import AppHeader from './Common/AppHeader.vue';
  import ProductsBanner from './ProductsBanner.vue';
  import ProductsInfo from './ProductsInfo.vue';
  import ContactForm from './Common/ContactForm.vue';
  import AppFooter from './Common/AppFooter.vue';
  
  export default {
    name: 'ProductsPage',
    components: {
      AppHeader,
      ProductsBanner,
      ProductsInfo,
      ContactForm,
      AppFooter
    }
  }
  </script>
  
  <style>
  </style>
  