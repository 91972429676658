<template>
  <div class="kaarvi-header-home">
    <AppHeaderOrange/>
  </div>
  <div>
    <div id="myCarousel" class="carousel slide" data-bs-ride="true">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active" style="background-color: #EC8540;">
        <img src="../assets/slider/FRUITS.png" class="d-block w-100" alt="...">        
        <div class="kaarvi-banner-text">
            <p>Fresh & Healthy<br/></p>
            <h2><strong>FRUITS</strong></h2>
        </div>
        <div class="kaarvi-know-more">
          <a class="btn btn-outline-secondary" href="/products">know More</a>
        </div>
      </div>
      <div class="carousel-item" style="background-color: #CA8A74;">
        <img src="../assets/slider/BEVERAGES.png" class="d-block w-100" alt="...">      
        <div class="kaarvi-banner-text">
            <p>Pure & Refreshing<br/></p>
            <h2><strong>BEVERAGES</strong></h2>
        </div>
        <div class="kaarvi-know-more">
          <a class="btn btn-outline-secondary" href="/products">know More</a>
        </div>
      </div>
      <div class="carousel-item" style="background-color: #79C262;">
        <img src="../assets/slider/VEGGIES.png" class="d-block w-100" alt="...">      
        <div class="kaarvi-banner-text">
            <p>Fresh & Healthy<br/></p>
            <h2><strong>VEGGIES</strong></h2>
        </div>
        <div class="kaarvi-know-more">
          <a class="btn btn-outline-secondary" href="/products">know More</a>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  </div>
</template>

<script>
  import AppHeaderOrange from './Common/AppHeaderOrange.vue';

  export default {
    name: 'HomeSlider',
    components: {
      AppHeaderOrange
    },
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>
<style>
@media screen and (min-width: 640px) {
  .kaarvi-header-home {
    z-index: 9999;
    position: absolute;
    top: 0;
    width: 100%;
  } 
}
.carousel-item {
  padding-top: 116px;
}
.carousel-item img {  
  animation: translateImage 1s;
}
.kaarvi-banner-text {
    color: #ffffff;
    position: absolute;
    top: 20%;
    left: 15%;
    animation: translateDown 1s;
}
@keyframes translateDown {
  0% {transform: translateY(-200px);}
  100% {transform: translateY(0);}
}
@keyframes translateUp {
  0% {transform: translateY(200px);}
  100% {transform: translateY(0);}
}
@keyframes translateImage {
  0% {transform: translateX(500px);}
  100% {transform: translateX(0);}
}
.kaarvi-banner-text h2 {
    font-size: 4rem;
}
.kaarvi-banner-text p {
    font-size: 2.3rem;
}
.kaarvi-know-more {
  position: absolute;
  left: 20%;
  bottom: 50px;
  animation: translateUp 1s;
}
.kaarvi-know-more a {
  color: #ffffff;
  border-radius: 10px;
  border-color: #ffffff;
  /* display: inline-block;
  padding: 10px;
  background-color: #ccc;
  color: #fff;
  border: 1px solid #000;
  border-radius: 5px; */
}
@media screen and (max-width: 640px) {
  .kaarvi-banner-text {
      left: 10%;
  }
  .kaarvi-banner-text h2 {
      font-size: 1.5rem;
  }
  .kaarvi-banner-text p {
      font-size: .625rem;
  }
  .kaarvi-know-more {
    left: 15%;
    bottom: 120px;
  }
  .kaarvi-know-more a {
      font-size: .5rem;
  }
}
</style>