<template>
    <div class="kaarvi-faqs">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <h2>FAQs</h2>
                <div class="kaarvi-faq-accordion">
                    <p>Certainly! Here are some frequently asked questions (FAQs) related to freeze drying:</p>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                What is freeze drying?
                            </button>
                            </h3>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Freeze drying, also known as lyophilization, is a preservation technique that involves
removing moisture from a product while it is frozen. This process helps to extend the
shelf life of various items, such as food, pharmaceuticals, and biological materials, while
preserving their quality and characteristics.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                What are the benefits of freeze drying?
                            </button>
                            </h3>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Freeze drying offers numerous advantages, including:
                                <ul>
                                    <li>Preservation of taste, aroma, and nutritional value.</li>
                                    <li>Extended shelf life without the need for additives or preservatives.</li>
                                    <li>Lightweight and easy to transport due to the removal of water content.</li>
                                    <li>Retention of product appearance, shape, and texture after rehydration.</li>
                                    <li>Enhanced stability, allowing for long-term storage.</li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                What types of products can be freeze-dried?
                            </button>
                            </h3>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                A wide range of products can be freeze-dried, including fruits, vegetables, meats,
seafood, dairy products, coffee, pharmaceuticals, vaccines, enzymes, and more. Freeze
drying is applicable to both perishable and sensitive items.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Is freeze-dried food safe to consume?
                            </button>
                            </h3>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Yes, freeze-dried food is generally safe to consume. The process of freeze drying helps
to preserve the nutritional content and reduce the risk of bacterial growth. However, it
is essential to ensure proper handling, storage, and rehydration according to the
manufacturer&#39;s instructions.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Can freeze-dried products be rehydrated?
                            </button>
                            </h3>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Yes, freeze-dried products can be easily rehydrated by adding water or other liquids.
Rehydration allows the products to regain their original texture, flavor, and appearance,
making them ready to consume.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingSix">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                How long does freeze-dried food last?
                            </button>
                            </h3>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Properly stored freeze-dried food can have a significantly extended shelf life. Depending
on the product and storage conditions, freeze-dried food can typically last anywhere
from several months to several years.
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h3 class="accordion-header" id="headingSeven">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                Is freeze drying an environmentally friendly process?
                            </button>
                            </h3>
                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                Freeze drying is generally considered to be an environmentally friendly process. It
requires less energy compared to other preservation methods, reduces food waste, and
often eliminates the need for additional preservatives or packaging.
                            </div>
                            </div>
                        </div>
                    </div>
                    <p><br><br>It's important to note that these FAQs provide general information, and specific details or
requirements may vary depending on the product and manufacturer.</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'FAQs'
}
</script>
<style scoped>
.kaarvi-faqs {
    padding: 3.5rem;
}
.kaarvi-faqs h2 {
    padding: 15px 0;
    color: #77787B;
}
.kaarvi-faqs .accordion-item {
    border: none;
}
.kaarvi-faqs h3 {
    border-bottom: 1.5px solid #77787B;
    color: #77787B;
    font-family: kaarviText;
}
.kaarvi-faqs h3 button {
    background-color: transparent;
}
.accordion-button:not(.collapsed) {
    color: #F15A22;
}
.accordion-body {
    text-align: left;
}
.accordion-button::after {
    content: "+";
    background-image: none;
    font-size: 1.625rem;
    color: #F15A22;
}
.accordion-button:not(.collapsed)::after {
    content: "-";
    background-image: none;
    font-size: 1.625rem;
    transform: none;
}
.accordion-button:focus {
    border: none;
    box-shadow: none;
}
</style>
