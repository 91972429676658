<template>
    <AppHeader/>
    <AboutBanner/>
    <AboutUsInfo/>
    <FAQs/>
    <ContactForm/>
    <AppFooter/>
  </template>
  
  <script>
  import AppHeader from './Common/AppHeader.vue';
  import AboutBanner from './AboutBanner.vue';
  import AboutUsInfo from './AboutUsInfo.vue';
  import FAQs from './Common/FAQs.vue';
  import ContactForm from './Common/ContactForm.vue';
  import AppFooter from './Common/AppFooter.vue';
  
  export default {
    name: 'AboutPage',
    components: {
      AppHeader,
      AboutBanner,
      AboutUsInfo,
      FAQs,
      ContactForm,
      AppFooter
    }
  }
  </script>
  
  <style>
  </style>
  