<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@font-face {
  font-family: kaarviHeading;
  src: url(assets/fonts/black_rocker/BlackRocker.ttf);
}
@font-face {
  font-family: kaarviTextItalic;
  src: url(assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf);
}
@font-face {
  font-family: kaarviText;
  src: url(assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf);
}
h1, h2, h3 {
  font-family: kaarviHeading, Arial;
}
p, span, div {
  font-family: kaarviText;
}
</style>
