<template>
    <AppHeader/>
    <ContactBanner/>
    <ContactInfo/>
    <ContactForm/>
    <AppFooter/>
  </template>
  
  <script>
  import AppHeader from './Common/AppHeader.vue';
  import ContactBanner from './ContactBanner.vue';
  import ContactInfo from './ContactInfo.vue';
  import ContactForm from './Common/ContactForm.vue';
  import AppFooter from './Common/AppFooter.vue';
  
  export default {
    name: 'ContactPage',
    components: {
      AppHeader,
      ContactBanner,
      ContactInfo,
      ContactForm,
      AppFooter
    }
  }
  </script>
  
  <style>
  </style>
  