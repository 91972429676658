<template>
    <AppHeader/>
    <BlogsInfo/>
    <ContactForm/>
    <AppFooter/>
  </template>
  
  <script>
  import AppHeader from './Common/AppHeader.vue';
  import BlogsInfo from './BlogsInfo.vue';
  import ContactForm from './Common/ContactForm.vue';
  import AppFooter from './Common/AppFooter.vue';
  
  export default {
    name: 'BlogPage',
    components: {
      AppHeader,
      BlogsInfo,
      ContactForm,
      AppFooter
    }
  }
  </script>
  
  <style>
  </style>
  