<template>
    <div class="kaarvi-footer container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                       <div class="hide-mobile">
                            <a class="navbar-brand" href="#"><img src="../../assets/logo.png" alt="Kaarvi Foods" width="100"/></a>
                       </div>
                       <SocialLink/>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="list-group">
                                    <router-link to="/" class="list-group-item-action">Home</router-link>
                                    <router-link to="products" class="list-group-item-action">Products</router-link>
                                    <router-link to="services" class="list-group-item-action">Services</router-link>
                                    <router-link to="blog" class="list-group-item-action">Blog</router-link>
                                    <router-link to="about" class="list-group-item-action">About</router-link>
                                    <router-link to="contact" class="list-group-item-action">Contact</router-link>
                                </div>
                            </div>
                            <div class="col-md-6">                                
                                <div class="list-group">
                                    <!-- <a href="#" class="list-group-item-action">Downloads</a> -->
                                    <a href="javascript:void(0)" class="list-group-item-action" @click="showPrivacyPolicy">Privacy Policy</a>
                                    <a href="javascript:void(0)" class="list-group-item-action" @click="showTermsOfService">Terms of Service</a>
                                    <!-- <a href="#" class="list-group-item-action">Cookie Settings</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row kaarvi-contact">            
                    <div class="col-md-6">
                        <span>ADDRESS</span>
                        <p>
                            <strong>Kaarvi Nutraceuticals & <br>
                            Foods Pvt. Ltd.</strong><br>
                            Gat No. 468, Kusgaon Khind Road, <br>
                            Tal: Bhor, Dist: Pune - 412205 <br>
                            Maharashtra, INDIA
                        </p>
                    </div>
                    <div class="col-md-6">
                        <span>CONTACT INFO</span>
                        <p>
                            <strong>Contact: </strong> 
                            <a href="" class="link-dark">+91 8007 187 185 </a><br>
                            <strong>E-mail: </strong>
                            <a href="mailto:webin@kaarvifoods.com" class="link-dark">webin@kaarvifoods.com</a>
                        </p>
                    </div>
                </div>
                <div class="row">                    
                    <div class="col-md-12">
                        <p class="kaarvi-copyrights">Copyright © 2023 Kaarvi Foods. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
<!-- Privacy Policy Modal -->
        <div class="bootstrap-modal-no-jquery" v-if="displayPrivacyPolicyModal">
            <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Privacy Policy</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="closePrivacyPolicy">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Last Updated: 31-Aug-2023</p>
                    <p>Welcome to <a href="https://www.kaarvifoods.com" title="kaarvi foods">https://www.kaarvifoods.com</a>. Your privacy is important to us. To better protect your privacy, we provide this Privacy Policy explaining our online information practices and the choices you can make regarding the way your information is collected and used. By accessing or using our website, you agree to the terms of this Privacy Policy.</p>
                    <ol>
                        <li>Information We Collect:
                            <p>We may collect personal information from you in various ways, including when you visit our website, fill out a form, subscribe to our newsletter, make a purchase, or interact with our website's features and services. The types of personal information we may collect include but are not limited to:</p>
                            <ul>
                                <li>Name</li>
                                <li>Contact information (email address, phone number, mailing address)</li>
                                <li>Demographic information (age, gender, location)</li>
                                <li>Payment information for purchases</li>
                                <li>Information you provide in forms or surveys</li>
                            </ul>
                        </li>
                        <li>How We Use Your Information
                            <p>We may use the information we collect for various purposes, including but not limited to:</p>
                            <ul>
                                <li>Providing, maintaining, and improving our website and services</li>
                                <li>Responding to your inquiries, comments, or requests</li>
                                <li>Sending newsletters, promotional materials, and updates about our products and services</li>
                                <li>Processing transactions and sending order confirmations</li>
                                <li>Conducting market research and analyzing usage patterns</li>
                                <li>Personalizing your experience on our website</li>
                            </ul>
                        </li>
                        <li>Data Security
                            <p>We take the security of your information seriously and implement appropriate technical and organizational measures to protect it. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>
                        </li>
                        <li>Cookies and Tracking Technologies
                            <p>Our website may use cookies and similar tracking technologies to enhance your experience and collect information about how you use the site. This information may include your IP address, browser type, operating system, and browsing behavior. You can manage your cookie preferences through your browser settings.</p>
                        </li>
                        <li>Third-Party Disclosure
                            <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except for the following cases:</p>
                            <ul>
                                <li>Trusted third parties who assist us in operating our website and providing services</li>
                                <li>When required by law or to protect our rights, safety, or property</li>
                            </ul>
                        </li>
                        <li>Links to Third-Party Websites
                            <p>Our website may contain links to third-party websites that have their own privacy policies. We are not responsible for the content or practices of these linked sites. We encourage you to review the privacy policies of these websites before providing any personal information.</p>
                        </li>
                        <li>Your Choices
                            <p>You can choose to limit the collection and use of your personal information by:</p>
                            <ul>
                                <li>Adjusting your browser settings to disable cookies or receive notifications when cookies are being sent</li>
                                <li>Unsubscribing from our newsletters and promotional emails using the provided links</li>
                                <li>Contacting us to update, correct, or delete your personal information</li>
                            </ul>
                        </li>
                        <li>Children's Privacy
                            <p>Our website is not intended for individuals under the age of 16. We do not knowingly collect personal information from children. If you believe we have inadvertently collected such information, please contact us to have it removed.</p>
                        </li>
                        <li>Changes to this Privacy Policy
                            <p>We reserve the right to modify this Privacy Policy at any time. Changes and clarifications will take effect immediately upon posting on the website. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting and using your information.</p>
                        </li>
                        <li>Contact Us
                            <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:webin@kaarvifoods.com">webin@kaarvifoods.com</a></p>
                        </li>
                    </ol>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            By using the Website, you agree to the terms of this Privacy Policy.
                        </label>
                        </div>
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                            By using our website, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our website. Your continued use of the website following the posting of changes to this policy will be deemed as your acceptance of those changes.
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closePrivacyPolicy">Close</button>
                </div>
                </div>
            </div>
            </div>
        </div>
<!-- Privacy Terms Modal -->
        <div class="bootstrap-modal-no-jquery" v-if="displayTermsOfServiceModal">
            <div class="modal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Terms & Conditions</h5>
                        <button type="button" class="close" data-dismiss="modal" @click="closeTermsOfService">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Welcome to Kaarvi Foods - Healthy Harvest!</p>
                        <p>These terms and conditions outline the rules and regulations for the use of Kaarvi Nutraceuticals & Foods Pvt Ltd's Website, located at <a href="https://www.kaarvifoods.com" title="kaarvi foods">https://www.kaarvifoods.com</a>.</p>
                        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Kaarvi Foods - Healthy Harvest if you do not agree to take all of the terms and conditions stated on this page. </p>
                        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements:
                            <br>
                            "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. <br>
                            "The Company", "Ourselves", "We", "Our" and "Us", refers to our <b>Company</b>. <br>
                            "Party", "Parties", or "Us", refers to both the Client and ourselves. <br>
                            All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
                        </p>
                        <p>
                            <b>Intellectual Property</b><br>
                            All content on the website, including text, graphics, images, logos, and other materials, is protected by intellectual property laws and is the property of Kaarvi Foods or its licensors. You may not reproduce, distribute, modify, transmit, or use any content from the website without our prior written consent.
                        </p>
                        <p>
                            <b>Cookies</b><br>
                            We employ the use of cookies. By accessing <a href="https://www.kaarvifoods.com" title="kaarvi foods">Kaarvi Foods</a>, you agreed to use cookies in agreement with the Kaarvi Nutraceuticals & Foods Pvt Ltd's <a href="javascript:void(0)" @click="showPrivacyPolicy">Privacy Policy</a>.<br>
Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
                        </p>
                        <p>
                            <b>License, User Conduct</b>
                            Unless otherwise stated, Kaarvi Nutraceuticals & Foods Pvt Ltd and/or its licensors own the intellectual property rights for all material on Kaarvi Foods. All intellectual property rights are reserved. You may access this from Kaarvi Foods for your own personal use subjected to restrictions set in these terms and conditions.
                            <br>When using the website, you agree not to:
                            <ul>
                                <li>Violate any applicable laws or regulations</li>
                                <li>Infringe upon the rights of others</li>
                                <li>Attempt to interfere with or disrupt the website's functionality</li>
                                <li>Upload or distribute malicious software or content</li>
                                <li>Use the website for any illegal, harmful, or unethical purpose</li>
                            </ul>
                            You must not:
                            <ul>
                                <li>Republish material from <a href="https://www.kaarvifoods.com" title="kaarvi foods">Kaarvi Foods</a></li>
                                <li>Sell, rent or sub-license material from <a href="https://www.kaarvifoods.com" title="kaarvi foods">Kaarvi Foods</a></li>
                                <li>Reproduce, duplicate or copy material from <a href="https://www.kaarvifoods.com" title="kaarvi foods">Kaarvi Foods</a></li>
                                <li>Redistribute content from <a href="https://www.kaarvifoods.com" title="kaarvi foods">Kaarvi Foods</a></li>
                            </ul>
                            This Agreement shall begin on the date and time when you start accessing Our website hereof.
                            <br>
                            Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. We do not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect Our views and opinions, its agents and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, We shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
                            <br>
                            We reserve the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.
                            <br>
                            You warrant and represent that:
                            <ul>
                                <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so.</li>
                                <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party.</li>
                                <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                                <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                            </ul>
                            You hereby grant Kaarvi Nutraceuticals & Foods Pvt Ltd a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.
                        </p>
                        <p>
                            <b>Hyperlinking to our Content</b>
                            <br>The following organizations may link to our Website without prior written approval:
                            <ul>
                                <li>Government agencies</li>
                                <li>Search engines</li>
                                <li>News organizations</li>
                                <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                                <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.</li>
                            </ul>
                            These organizations may link to our home page, to publications or to other Website information so long as the link:
                            <br>
                            (a) is not in any way deceptive
                            <br>
                            (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and 
                            <br>
                            (c) fits within the context of the linking party’s site.
                        </p>
                        <p>
                            We may consider and approve other link requests from the following types of organizations:
                            <ul>
                                <li>commonly-known consumer and/or business information sources</li>
                                <li><a href="http://dot.com/" title="dot com">dot.com</a> community sites</li>
                                <li>associations or other groups representing charities</li>
                                <li>online directory distributors</li>
                                <li>internet portals</li>
                                <li>accounting, law and consulting firms; and</li>
                                <li>educational institutions and trade associations.</li>
                            </ul>
                        </p>
                        <p>
                            We will approve link requests from these organizations if we decide that: <br>
(a) the link would not make us look unfavourably to ourselves or to our accredited businesses;<br>
(b) the organization does not have any negative records with us; <br>
(c) the benefit to us from the visibility of the hyperlink compensates the absence of Kaarvi Nutraceuticals & Foods Pvt Ltd; and <br>
(d) the link is in the context of general resource information.
                        </p>
                        <p>
                            These organizations may link to our home page so long as the link: <br>
(a) is not in any way deceptive; <br>
(b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and <br>
(c) fits within the context of the linking party’s site.
                        </p>
                        <p>
                            If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Kaarvi Nutraceuticals & Foods Pvt Ltd. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
                        </p>

                        <p>
                            Approved organizations may hyperlink to our Website as follows:
                            <ul>
                                <li>By use of our corporate name; or</li>
                                <li>By use of the uniform resource locator being linked to; or</li>
                                <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                            </ul>
                            No use of Kaarvi Nutraceuticals & Foods Pvt Ltd's logo or other artwork will be allowed for linking absent a trademark license agreement.
                        </p>
                        <p>
                            <b>iFrames</b>
                            <br>
                            Without prior approval and written permission, you may not create frames around our Web pages that alter in any way the visual presentation or appearance of our Website.
                        </p>
                        <p>
                            <b>Content Liability</b>
                            <br>
                            We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                        </p>
                        <p>
                            <b>Your Privacy</b>
                            <br>Your use of the website is also governed by our Privacy Policy, which can be found <a href="javascript:void(0)" @click="showPrivacyPolicy">Privacy Policy</a>. By using the website, you consent to the collection, use, and sharing of your information as described in the Privacy Policy. Please read <a href="javascript:void(0)" @click="showPrivacyPolicy">Privacy Policy</a> carefully
                        </p>
                        <p>
                            <b>Reservation of Rights</b>
                            <br>
                            We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                        </p>
                        <p>
                            <b>Removal of links from our website</b>
                            <br>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.
<br>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                        </p>
                        <p>
                            <b>Disclaimer</b>
                            <br>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                            <ul>
                                <li>limit or exclude our or your liability for death or personal injury</li>
                                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation</li>
                                <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                                <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                            </ul>
                            The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: <br>
(a) are subject to the preceding paragraph; and <br>
(b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.<br>
As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
                        </p>
                        <p>
                            <b>Limitation of Liability</b>
                            <br>To the fullest extent permitted by law, Kaarvi Foods shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of the website, or any conduct or content of any third party on the website.
                        </p>
                        <p>
                            <b>Indemnification</b>
                            <br>You agree to indemnify and hold Kaarvi Foods harmless from any claims, losses, liabilities, damages, costs, and expenses, including attorney's fees, arising out of your use of the website, your violation of these Terms, or your violation of any rights of another.
                        </p>
                        <p>
                            <b>Governing Law and Jurisdiction</b>
                            <br>These Terms shall be governed by and construed in accordance with the laws of Pune, India. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Pune, India.
                        </p>
                        <p>
                            <b>Contact Us</b>
                            <br>If you have any questions, concerns, or inquiries about these Terms, please contact us at <a href="mailto:webin@kaarvifoods.com" title="Kaarvi Foods">webin@kaarvifoods.com</a>   <br>
By using the website, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the website. Your continued use of the website constitutes your acceptance of these Terms.

                        </p>




                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeTermsOfService">Close</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SocialLink from './SocialLink.vue';

export default {
    name: 'AppFooter',
    components: {
        SocialLink
    },
    data() {
      return {
        displayPrivacyPolicyModal: false,
        displayTermsOfServiceModal: false,
      }
    },
    methods: {
        showPrivacyPolicy() {
            this.displayPrivacyPolicyModal = true;
            this.displayTermsOfServiceModal = false;
        },
        closePrivacyPolicy() {
            this.displayPrivacyPolicyModal = false;
        },
        showTermsOfService() {
            this.displayTermsOfServiceModal = true;
            this.displayPrivacyPolicyModal = false;
        },
        closeTermsOfService() {
            this.displayTermsOfServiceModal = false;
        },
    }
    
}
</script>

<style>
.kaarvi-footer {
    background-color: #dfdfdf;
    padding: 3.5rem;
}
.list-group, .kaarvi-contact {
    text-align: left;
    font-size: 14px;
    color: #141415;
}
.list-group a {
    text-decoration: none;
    margin-bottom: 5px;
}
.list-group a:hover, .list-group a:active {
    background-color: transparent;
    color: #F15A22;
    font-weight: 700;
}
.kaarvi-contact span {
    font-size: 10px;
    color: #77787B;
}
.kaarvi-contact a {
    text-decoration: none;
}
.kaarvi-copyrights {
    text-align: right;
    padding: 20px 0 0;
    font-size: 12px;
}
.modal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    transition: all 0.3s linear;
}
.modal-dialog {
    width: 60%;
    max-width: 700px;
    height: 80%;
    overflow: auto;
    top: 10%;
    margin: 0 auto;
    text-align: left;
}
.modal-content{
    height: 100%;
}
.modal-body {
    font-size: 0.675rem;
    position: absolute;
    height: 70%;
    top: 65px;
    bottom: 0;
    overflow: auto;
}
.modal-footer {
    position: fixed;
    bottom: 60px;
    width: 60%;
    max-width: 700px;
}
.close {
    border: none;
}
@media screen and (max-width: 640px) {
    .hide-mobile {
        display: none;
    }
    .kaarvi-footer {
        padding: 1rem;
    }
    .list-group, .kaarvi-contact {
        text-align: center;
        padding: 10px 0;
    }
    .kaarvi-copyrights {
        text-align: center;
    }
}
</style>