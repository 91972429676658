<template>
  <div class="kaarvi-header">
    <div class="row">
      <div class="col-lg-2">
        <a class="kaarvi-call hide-mobile" href="#"><img src="../../assets/call.png" alt="+91 8007 187 185"/><span>+91 8007 187 185</span></a>
        <img class="show-mobile" width="84" src="../../assets/logo.png" />
      </div>
      <div class="col-lg-8 justify-content-center">
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li v-for="(item, index) in items" :key="index">
                  <router-link class="nav-link" :to="item.link">
                    <span v-if="item.text">{{ item.text }}</span>
                    <img v-if="item.img" class="hide-mobile" width="84" src="../../assets/logo.png" />
                  </router-link>
                </li>
              </ul>
            </div> 
          </div>
        </nav> 
      </div>
      <div class="col-lg-2 kaarvi-search">
        <!-- <div>
          <input type="text" class="form-control" placeholder="Search" aria-label="Search">
          <img src="../../assets/search-black.png" alt="search"/>
        </div> -->
      </div>
    </div>  
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  props: {
  },
  data() {
    return {
      items: [
        { text: 'Home',
          link:'/' 
        }, 
        { text: 'Products',
          link: 'products'
        },
        {
          text: 'Services',
          link: 'services'
        },
        {
          img: '../assets/logo.png',
          link: '/'
        },
        {
          text: 'Blog',
          link: 'blog'
        },
        {
          text: 'About',
          link: 'about'
        },
        {
          text: 'Contact',
          link: 'contact'
        }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.show-mobile {
  display: none;
}
.kaarvi-header {
  padding: 1rem 3.5rem;
}
.kaarvi-header a span {
  display: inline-block;
  margin: 20px 0;
  color: #141415;
}
.navbar-expand-lg {
  padding: 0;
}
.navbar-expand-lg .navbar-nav {
  margin: auto;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}
a.kaarvi-call span {
  color: #141415;
}
.kaarvi-search input {
  background-color: transparent;
  border: 2px solid #141415;
  border-radius: 15px;
  line-height: 1rem;
  margin: 1rem 0;
}
.kaarvi-search input:focus {
  box-shadow: none;
}
.kaarvi-search div {
  position: relative;
}
.kaarvi-search div img {
  position: absolute;
  top: 5px;
  right: 10px;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #141415;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #141415;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #141415;
}
@media screen and (max-width: 640px) {
  .hide-mobile{
    display: none;
  }
  img.show-mobile {
    display: block;
    position: absolute;
    right: 25px;
    width: 50px;
  }
  .kaarvi-header{
    padding: 1rem;
  }
  .kaarvi-header a span {
    color: #000;
    margin: 10px;
  }
}
</style>
