<template>
  <div class="kaarvi-services-info">
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <div class="row kaarvi-services-container">
          <div class="col-lg-6 kaarvi-img">
            <img src="../assets/services/services1.png" alt="Services" width="500" />
            <div class="kaarvi-corner">
              <img
                src="../assets/services/service1-corner.png"
                alt="service corner"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <h2>Freeze Drying Job Work:</h2>
            <p>
              We welcome you to bring your products and specifications to us for
              our professional freeze drying job work services. Our expertise
              lies in various freeze-dried segments, including Ready-To-Eat
              (RTE), Ready-To-Cook (RTC), and Ready-To-Drink (RTD) products like
              gravies, masala curries, and dairy products such as DVS cultures.
              Additionally, we excel in freeze-drying seasonal fruits,
              vegetables, and proprietary food items like milkshakes and detox
              drinks. With our advanced freeze-drying technology and skilled
              team, we ensure the preservation of your products' original
              flavors, textures, and nutritional value, delivering top-quality
              freeze-dried products tailored to your needs.
            </p>
          </div>
        </div>
        <div class="row kaarvi-services-container">
          <div class="col-lg-6">
            <h2>Contract Manufacturing /Private Labeling:</h2>
            <p>
              Freeze drying product contract manufacturing and private labeling
              are valuable services that allow businesses to expand their
              product offerings without investing in specialized equipment or
              infrastructure. With contract manufacturing, businesses can
              outsource the freeze-drying process to experts, ensuring
              high-quality results and efficient production. Private labeling
              allows businesses to put their brand on premium freeze-dried
              products, enhancing their market presence and customer loyalty.
              Both services offer cost-effective solutions, collaborative
              partnerships, and a seamless way to deliver exceptional
              freeze-dried products to the market.
              <a
                v-if="!readMorePart2"
                @click="activateReadMorePart2"
                href="javascript:void(0)"
                >Read more</a
              >
              <span v-if="readMorePart2"
                ><br /><br />As a leading provider of freeze drying product
                services, we take pride in offering flexible and customized
                solutions to meet your unique manufacturing and branding needs.
                <br /><br />Whether you are a start-up, a small business, or an
                established brand, our freeze drying contract manufacturing and
                private labeling services provide you with a competitive
                advantage in the industry. Partner with us to bring your
                freeze-dried product ideas to life and deliver exceptional
                products to your customers under your brand name.
                <a
                  v-if="readMorePart2"
                  @click="activateReadLessPart2"
                  href="javascript:void(0)"
                  >Read less</a
                >
              </span>
            </p>
          </div>
          <div class="col-lg-6 kaarvi-img-right">
            <img src="../assets/services/services2.png" alt="Services" width="500" />
            <div class="kaarvi-corner-right">
              <img
                src="../assets/services/service2-corner.png"
                alt="service corner"
              />
            </div>
          </div>
        </div>
        <div class="row kaarvi-services-container">
          <div class="col-lg-6 kaarvi-img">
            <img src="../assets/services/services3.png" alt="Services" width="500" />
            <div class="kaarvi-corner">
              <img
                src="../assets/services/service3-corner.png"
                alt="service corner"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <h2>BULK SUPPLY:</h2>
            <p>
              Bulk supply of freeze-dried products offers businesses the
              convenience of procuring large quantities of high-quality
              freeze-dried items. By ordering in bulk, businesses can benefit
              from cost savings, efficient inventory management, and consistent
              product availability. Freeze-dried products in bulk have an
              extended shelf life, making them ideal for long-term storage or
              seasonal demands. With reliable partnerships and strict quality
              control, bulk supply ensures businesses can meet their customer
              demands and capitalize on the growing market for freeze-dried
              products. <br />If you are looking for a dependable bulk supplier
              of freeze-dried products, we are here to fulfill your requirements
              and contribute to the success of your business. Contact us today
              to discuss your needs and explore how we can meet your bulk
              freeze-dried product supply needs.
            </p>
          </div>
        </div>
        <div class="row kaarvi-services-container">
          <div class="col-lg-6">
            <h2>New Product Development (NPD):</h2>
            <p>
              New product development in freeze drying is an innovative process
              that focuses on creating new and improved freeze-dried products.
              This involves utilizing advanced freeze-drying technology to
              preserve the original characteristics of various items, such as
              fruits, vegetables, meats, and more. The aim is to deliver
              products with extended shelf life, enhanced flavors, and improved
              nutritional value. Through research, formulation development, and
              quality control, Packaging & presentation, Compliance &
              regulations, businesses can offer unique freeze-dried options that
              cater to evolving consumer preferences and market demands.
              <a
                v-if="!readMorePart4"
                @click="activateReadMorePart4"
                href="javascript:void(0)"
                >Read more</a
              >
              <span v-if="readMorePart4"
                ><br /><br />Through continuous improvement and innovation, we
                remain committed to pushing the boundaries of freeze drying
                technology and new product development. We believe in fostering
                collaborative partnerships to create freeze-dried products that
                not only meet but exceed our clients' expectations.
                <br /><br />If you have a new product concept or are looking to
                innovate in freeze drying, we would love to be your partner in
                the exciting journey of new product development. Let us help you
                turn your ideas into reality and bring exceptional freeze-dried
                products to the market.
                <a
                  v-if="readMorePart4"
                  @click="activateReadLessPart4"
                  href="javascript:void(0)"
                  >Read less</a
                >
              </span>
            </p>
          </div>
          <div class="col-lg-6 kaarvi-img-right">
            <img src="../assets/services/services4.jpg" alt="Services" width="500" />
            <!-- <div class="kaarvi-corner-right">
              <img
                src="../assets/services/service3-corner.png"
                alt="service corner"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServiceInfo",
  data() {
    return {
      readMorePart1: false,
      readMorePart2: false,
      readMorePart3: false,
      readMorePart4: false,
    };
  },
  methods: {
    activateReadMorePart1() {
      this.readMorePart1 = true;
    },
    activateReadLessPart1() {
      this.readMorePart1 = false;
    },
    activateReadMorePart2() {
      this.readMorePart2 = true;
    },
    activateReadLessPart2() {
      this.readMorePart2 = false;
    },
    activateReadMorePart3() {
      this.readMorePart3 = true;
    },
    activateReadLessPart3() {
      this.readMorePart3 = false;
    },
    activateReadMorePart4() {
      this.readMorePart4 = true;
    },
    activateReadLessPart4() {
      this.readMorePart4 = false;
    },
  },
};
</script>
<style scoped>
.kaarvi-services-info {
  padding: 3rem 0 0;
}
.kaarvi-services-info > .row {
  margin: auto;
}
.kaarvi-services-container {
  padding: 1rem 0;
  text-align: left;
  font-size: 0.85rem;
}
.kaarvi-services-container h2 {
  color: #f15a22;
  font-family: kaarviText;
  padding: 1rem 2rem;
  margin: 0;
}
.kaarvi-services-container ul {
  padding-left: 3rem;
}
.kaarvi-services-container p {
  padding-left: 2rem;
}
.kaarvi-img,
.kaarvi-img-right {
  position: relative;
  cursor: pointer;
}
.kaarvi-img > img:hover {
    transform: rotate(-5deg);
    transition-duration: 1s;
}
.kaarvi-img-right > img:hover {
    /* animation: rotateMe 1s; */
    transform: rotate(5deg);
    transition-duration: 1s;
}
@keyframes rotateMe {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(5deg);}
}
.kaarvi-img-right {
  text-align: right;
}
.kaarvi-corner {
  position: absolute;
  left: -35px;
  top: -35px;
}
.kaarvi-corner-right {
  position: absolute;
  right: -50px;
  top: -50px;
}
@media screen and (max-width: 640px) {
    img {
        width: 100% !important;
    }
    .kaarvi-corner, .kaarvi-corner-right {
      display: none;
    }
}
</style>
