<template>
  <BlogsBanner v-if="showLess"/>
  <div class="kaarvi-blog-info">
    <div class="row" v-if="showLess">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <div class="row kaarvi-blog-container">
          <div class="col-lg-6 kaarvi-img">
            <img src="../assets/blogs/blog1.png" alt="Blog" width="500" />
          </div>
          <div class="col-lg-6 kaarvi-blog-content">
            <span><img src="../assets/blogs/calender-icon.png"/>April 24, 2023</span>
            <h2>The Future of Freeze Dried RTE, RTC & RTD Products:</h2>
            <p>The Future of Freeze-Dried RTE and RTC Products: Convenience, Nutrition, and Beyond
In the ever-changing landscape of convenience and nutrition, freeze-dried Ready-to-Eat (RTE) and Ready-to-Cook (RTC) products are emerging as the future of food. <br>
Here's a glimpse into what lies ahead:
<ol>
  <li>Ultimate Convenience: Imagine gourmet meals at your fingertips, prepared in minutes. The future of freeze-dried RTE & RTC products promises effortless meals, catering to our fast-paced lives. </li>
</ol>
</p>
            <a href="javascript:void(0)" @click="showMoreContent()">Read Blog</a>
          </div>
        </div>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row" v-if="!showLess">
      <div class="col-lg-1"></div>
      <div class="col-lg-10 kaarvi-blog-full-content ">
        <div>
          <h2>The Future of Freeze Dried RTE, RTC & RTD Products:</h2>
          <img src="../assets/blogs/blog-full.png" alt="Blog" />
        </div>
        <div>
          <span><img src="../assets/blogs/calender-icon.png"/>April 24, 2023</span><br><br><br>
          <p>The Future of Freeze-Dried RTE and RTC Products: Convenience, Nutrition, and Beyond
In the ever-changing landscape of convenience and nutrition, freeze-dried Ready-to-Eat (RTE) and Ready-to-Cook (RTC) products are emerging as the future of food. </p>
<p><b>Here's a glimpse into what lies ahead:</b></p>
<ol>
  <li>Ultimate Convenience: Imagine gourmet meals at your fingertips, prepared in minutes. The future of freeze-dried RTE & RTC products promises effortless meals, catering to our fast-paced lives.</li>
  <li>Nutrient-Rich Goodness: Expect nutrition to take center stage. These products retain essential vitamins and minerals, ensuring health-conscious choices are both quick and beneficial.</li>
  <li>Culinary Exploration Simplified: Flavors from around the world will grace our plates. As techniques advance, these products will capture authentic tastes, making global cuisine accessible.</li>
  <li>Eco-Friendly Focus: Sustainability will guide their evolution. With extended shelf lives and reduced waste, freeze-dried products are set to be eco-friendly staples.</li>
  <li>Customization Unleashed: Personalization will become standard. Tailor meals to your dietary needs, whether it's vegan, keto, or allergy-friendly, for a truly individualized experience.</li>
  <li>A Global Impact: Beyond convenience, these products could play a pivotal role in addressing food shortages and disaster relief efforts, offering nourishment when it's needed most.</li>
</ol>
<p><b>In Short:</b> The future of freeze-dried RTE & RTC products is a fusion of convenience, health, and sustainability, creating a culinary revolution that's both exciting and accessible. Prepare for a swift evolution that will redefine the way we eat.</p>
<a href="javascript:void(0)" @click="showLessContent()">Go To Blogs</a>        
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <div class="row kaarvi-blog-container">
          <div class="col-lg-6 kaarvi-img">
            <img src="../assets/blogs/blog2.png" alt="Blog" width="500" />
          </div>
          <div class="col-lg-6 kaarvi-blog-content">
            <span><img src="../assets/blogs/calender-icon.png"/>April 24, 2023</span>
            <h2>Pink Snack Ready To Go</h2>
            <p>Lorem ipsum, or lipsum as it is sometimes known, is
                dummy text used in laying out print, graphic or web
                designs. The passage is attributed to an unknown
                typesetter in the 15th century who is thought to have
                scrambled parts of Cicero's De Finibus Bonorum et
                Malorum for use in a type specimen book. </p>
            <a href="">Know More</a>
        </div>
      </div>
      <div class="col-lg-1"></div>
      </div>
    </div> -->
  </div>
</template>
<script>
import BlogsBanner from './BlogsBanner.vue';

export default {
  name: "BlogsInfo",
  components: {
    BlogsBanner
  },
  data() {
    return {
      showLess: true,
    };
  },
  methods: {
    showMoreContent() {
      this.showLess = false;
    },
    showLessContent() {
      this.showLess = true;
    }
  },
};
</script>
<style>
.kaarvi-blog-info {
  /* padding: 3rem 1rem 0; */
}
.kaarvi-blog-container {
  padding: 2rem;
  text-align: left;
  font-size: 0.85rem;
  position: relative;
}
.kaarvi-blog-content img,
.kaarvi-blog-full-content img {
    margin-right: 10px;
}
.kaarvi-blog-container h2,
.kaarvi-blog-full-content h2 {
  color: #77787B;
  font-family: kaarviText;
  padding: 1rem 0;
  font-size: 1.625rem;
  margin: 0;
}
.kaarvi-blog-full-content h2 {
  color: #F15A22;
}
.kaarvi-blog-content {
    position: absolute;
    left: 40%;
    top: 15%;
    background: #E2E2E2;
    padding: 2rem;
    border-radius: 20px;
    animation: translateBlogs 1s;
}
.kaarvi-blog-content a,
.kaarvi-blog-full-content a {
    color: #F15A22;
    text-decoration: none;
}
@keyframes translateBlogs {
  0% {transform: translateX(500px);}
  100% {transform: translateX(0);}
}
.kaarvi-blog-full-content {
  text-align: left;
  animation: translateBlogs 1s;
}
.kaarvi-blog-full-content div > img {
  width: 100%;
}
@media screen and (max-width: 640px) {
  .kaarvi-blog-content {
    position: initial;
    width: 100%;
    margin: 10px 0;
  }
  .kaarvi-img img {
    width: 100%;
  }
}
</style>
