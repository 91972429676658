<template>
    <HomeSlider/>
    <DriedProducts/>
    <DryingProcess/>
    <WhyUs/>
    <CertificationInfo/>
    <ContactForm/>
    <AppFooter/>
  </template>
  
  <script>
  import HomeSlider from './HomeSlider.vue';
  import DriedProducts from './DriedProducts.vue';
  import DryingProcess from './DryingProcess.vue';
  import WhyUs from './WhyUs.vue';
  import CertificationInfo from './CertificationInfo.vue';
  import ContactForm from './Common/ContactForm.vue';
  import AppFooter from './Common/AppFooter.vue';
  
  export default {
    name: 'HomePage',
    components: {
      HomeSlider,
      DriedProducts,
      DryingProcess,
      WhyUs,
      CertificationInfo,     
      ContactForm,
      AppFooter,
    }
  }
  </script>
  
  <style>
  </style>
  