<template>
    <div class="kaarvi-contact-form">
        <div class="row justify-content-md-center">
            <div class="col col-lg-7">
                <div class="row">
                    <div class="col-lg-5">
                        <h2>Hi. Nice to<br> meet you</h2>
                    </div>
                    <div class="col-lg-7">
                        <p v-if="showSuccess">Thank you for your email, we will contact you soon...</p>
                        <!-- <form class="kaarvi-form"> -->
                        <form ref="form" class="kaarvi-form" @submit.prevent="sendEmail">
                            <input type="text" name="user_name" class="form-control" id="name" placeholder="Name">
                            <input type="number" name="user_number" class="form-control" id="mobile" placeholder="Mobile">
                            <input type="email" name="user_email" class="form-control" id="email" placeholder="Email ID">
                            <textarea class="form-control" name="message" id="content" rows="4" placeholder="Tell us about your need"></textarea>
                            <button type="submit" class="btn btn-primary">Send</button>
                            <!-- mail to: webin@kaarvifoods.com -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { Email } from "../../assets/SMTP/smtp.js";
import emailjs from '@emailjs/browser';

export default {
    name: 'ContactForm',
    data() {
        return {
            user_name: "",
            user_email: "",
            user_number: "",
            message: "",
            showSuccess: false,
        }
    },
    methods: {
        mounted() {
            this.showSuccess = false;
            console.log('its mounted....');
        },
        sendEmail() {
        emailjs.sendForm('Kaarvi', 'template_f02ixc7', this.$refs.form, 'cFddzK7AzVYk0Eywx')
            .then((result) => {
                console.log('SUCCESS!', result.text);
                this.$refs.form.reset();
                this.showSuccess = true;
            }, (error) => {
                console.log('FAILED...', error.text);
            });
        }
  }
}
</script>
<style scoped>
.kaarvi-contact-form {
    padding: 3.5rem;
}
.kaarvi-form input, .kaarvi-form textarea {
    margin-bottom: 10px;
}
.kaarvi-contact-form h2 {
    color: #F15A22;
    font-family: kaarviText;
}
.kaarvi-form button {
    background-color: #F15A22;
    border: none;
    float: left;
}
@media screen and (max-width: 640px) {
    .kaarvi-contact-form {
        padding: 1rem;
    }
}
</style>