<template>
    <div class="kaarvi-certifications">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <h2>Certifications</h2>
                <p>KAARVI NUTRACEUTICALS & FOODS PVT. LTD. proudly holds the FSSC 22000 Food Quality & Safety certification under SGS, a globally recognized leader in food safety and quality management certification, accredited by UKAS. Our dedication to these standards guarantees that our products consistently meet rigorous international benchmarks, offering you peace of mind and assurance of quality.</p>
                <div class="kaarvi-certifications-container">
                    <ul class="list-group list-group-horizontal justify-content-md-center">
                        <li class="list-group-item">
                            <img src="../assets/certificates/sgs.png" width="150" alt="System Certification"/>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/certificates/FSSC_22000.png" width="320" class="padding-r" alt="FSSC 22000"/>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/certificates/ukas.png" width="80" alt="UKAS Management Systems"/>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/certificates/fssai.jpg" width="200" alt="FSSAI"/>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/certificates/apeda.jpg" width="120" alt="Apeda"/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CertificationInfo'
}
</script>
<style scoped>
.kaarvi-certifications {
    padding: 0 3.5rem 3.5rem;
    border-bottom: 1px solid #dfdfdf;
}
.kaarvi-certifications h2 {
    padding: 15px 0;
    color: #77787B;
}
.kaarvi-certifications-container .list-group-item {
    border: none;
    display: flex;
    align-items: center;
    padding: 0;
}
.kaarvi-certifications-container .list-group-item .padding-r {
    padding-right: 1.625rem;
    margin-top: -16px;
}
@media screen and (max-width: 640px) {
    .kaarvi-certifications {
        padding: 1rem 0 2rem;
    }
    .list-group-horizontal {
        flex-direction: column;
    }
    .kaarvi-certifications-container .list-group-item {
        display: block;
    }
    .kaarvi-certifications-container .list-group-item .padding-r {
        padding-right: 0;
        padding-bottom: 20px;
        margin: 0;
    }
}
</style>
