<template>
    <div class="kaarvi-banner">
    </div>
</template>

<script>
export default {
    name: 'BlogsBanner'
}
</script>
<style scoped>
.kaarvi-banner {
    background: url('../assets/blogs/HeroSection.png') no-repeat center center;
    width: 100%;
    height: 500px;
    position: relative;
}
</style>