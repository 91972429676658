<template>
    <div class="kaarvi-whyus">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <h2>Why Us?</h2>
                <div class="kaarvi-why-us-image">
                    <img src="../assets/why-us.png" alt="Why Us"/>
                </div>
                <p>There are several reasons why choosing our freeze drying business can be advantageous:</p>
                <ul>
                    <li><strong>Expertise:</strong> We possess extensive knowledge and expertise in freeze drying technology,
ensuring that your products receive the highest level of quality and preservation.</li>
<li><strong>Quality Assurance:</strong> We prioritize maintaining the quality, taste, and nutritional value of
the freeze-dried products throughout the entire process. Our rigorous quality control
measures ensure consistency and customer satisfaction.</li>
<li><strong>Customization:</strong> We offer the flexibility to tailor the freeze drying process to meet your
specific requirements. Whether it's selecting ingredients, adjusting freeze drying
parameters, or packaging options, we strive to accommodate your unique needs.</li>
<li><strong>Cutting-Edge Facilities:</strong> Our state-of-the-art freeze drying facilities are equipped with
advanced technology, allowing for efficient and precise freeze drying operations. We
invest in modern infrastructure to ensure optimal results.</li>
<li><strong>Sustainability:</strong> We prioritize sustainability in our operations. Our freeze drying processes
are designed to minimize waste and energy consumption, reducing the environmental
impact of our business.</li>
<li><strong>Customer Focus:</strong> We value our customers and prioritize their satisfaction. Our dedicated
team provides excellent customer service, prompt communication, and a collaborative
approach to meet your expectations.</li>
<li><strong>Comprehensive Solutions:</strong> Beyond freeze drying, we offer end-to-end solutions,
including packaging, labeling, and logistics support. This comprehensive approach
streamlines the entire process for you.</li>
<li><strong>Innovation:</strong> We stay up-to-date with the latest advancements in freeze drying
technology and continually strive for innovation. By incorporating the latest techniques
and equipment, we ensure that you benefit from the best freeze drying practices
available.</li>
                </ul>
                <p>Overall, choosing our freeze drying business means partnering with experienced professionals
who prioritize quality, customization, sustainability, and customer satisfaction, while offering
comprehensive solutions and staying at the forefront of industry advancements.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyUs'
}
</script>
<style scoped>
.kaarvi-whyus {
    padding: 3.5rem;
}
.kaarvi-whyus h2 {
    color: #77787B;
}
.kaarvi-why-us-image {
    padding: 15px 0;
}
.kaarvi-whyus p, .kaarvi-whyus ul li {
    text-align: left;
}
.kaarvi-whyus ul li {
    list-style: none;
}
.kaarvi-whyus ul li strong {
    color: #EC8640;
}
.kaarvi-whyus ul li::before {
  content: "\2055";
  color: #EC8640;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
@media screen and (max-width: 640px) {
    .kaarvi-whyus {
        padding: 1rem;
    }
}
</style>