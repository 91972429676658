<template>
    <div class="kaarvi-social-links">
        <div class="row">
            <div class="col">
                <a href="https://www.linkedin.com/company/kaarvi-nutraceuticals-foods-p-ltd/about/" target="_blank">
                    <img src="../../assets/social/LinkedIn.png" alt="Kaarvi Linked In">
                </a>
            </div>
            <div class="col">
                <a href="https://instagram.com/kaarvifoods?igshid=ZDdkNTZiNTM=" target="_blank">
                    <img src="../../assets/social/Instagram.png" alt="Kaarvi Instagram">
                </a>
            </div>
            <div class="col">
                <a href="https://www.facebook.com/HealthyBhiPureBhi/" target="_blank">
                    <img src="../../assets/social/Facebook.png" alt="Kaarvi Facebook">
                </a>
            </div>
            <!-- <div class="col">
                <a href="https://www.facebook.com/HealthyBhiPureBhi/" target="_blank">
                    <img src="../../assets/social/Twitter.png" alt="Kaarvi Twitter">
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'SocialLink'
}
</script>
<style>
.kaarvi-social-links {
    padding: 3rem 3rem 0;
}
</style>