import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'
import App from './App.vue'
import HomePage from './components/HomePage.vue'
import ProductsPage from './components/ProductsPage.vue'
import ServicesPage from './components/ServicesPage.vue'
import BlogPage from './components/BlogPage.vue'
import AboutPage from './components/AboutPage.vue'
import ContactPage from './components/ContactPage.vue'

import {createRouter, createWebHistory} from 'vue-router'  

const routes = [
    { path: '/', component: HomePage },
    { path: '/products', component: ProductsPage },
    { path: '/services', component: ServicesPage },
    { path: '/blog', component: BlogPage },
    { path: '/about', component: AboutPage },
    { path: '/contact', component: ContactPage },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
createApp(App).use(router).mount('#app')

import 'bootstrap/dist/js/bootstrap.js'