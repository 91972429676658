<template>
    <div class="kaarvi-dried">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <h2>Freeze Dried Products & INGREDIENTS</h2>
                <p>We are a manufacturer of Freeze Dried products & ingredients that works in various product lines
such as Fruits, Vegetables, Herbs, Sprouts, RTE/RTC products, RTD Beverages, Dairy ingredients
and cultures.</p>
                <div class="kaarvi-dried-container">
                    <ul class="list-group list-group-horizontal justify-content-md-center">
                        <li class="list-group-item">
                            <img src="../assets/products/fruits.png" alt="Fruits"/>
                            <h4>FRUITS</h4>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/products/veggies.png" alt="Veggies"/>
                            <h4>VEGGIES</h4>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/products/herbs.png" alt="Herbs"/>
                            <h4>HERBS</h4>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/products/sprouts.png" alt="Sprouts"/>
                            <h4>SPROUTS</h4>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/products/rtc-rte.png" alt="Rtc-rte"/>
                            <h4>RTC/RTE</h4>
                        </li>
                        <li class="list-group-item">
                            <img src="../assets/products/beverages.png" alt="Beverages"/>
                            <h4>BEVERAGES</h4>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DriedProducts'
}
</script>
<style scoped>
.kaarvi-dried {
    padding: 3.5rem;
}
.kaarvi-dried h2 {
    padding: 15px 0;
    color: #77787B;
}
.kaarvi-dried-container img {
    width: 100px;
    cursor: pointer;
}
.kaarvi-dried-container img:hover {
    transform: translateY(-20px);
    /* animation: translateUp 2s; */
    transition-duration: 1s;
}
@keyframes translateUp {
  0% {transform: translateY(0);}
  100% {transform: translateY(-20px);}
}
.kaarvi-dried-container h4 {
    color: #77787B;
    font-size: 1rem;
    padding: 15px 0;
}
.kaarvi-dried-container .list-group-item {
    border: none;
    text-align: center;
}
@media screen and (max-width: 640px) {
    .kaarvi-dried {
        padding: 1rem;
    }
    .list-group-horizontal {
        flex-direction: column;
    }
    .kaarvi-dried-container .list-group-item {
        padding: 0;
    }
}
</style>
