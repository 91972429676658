<template>
    <div class="kaarvi-cntact-info">
        <h4>Have a question about one of our products, <br>
or want to learn more about our range of services?</h4>
        <p>
            <strong>+91 8007 187 185</strong>
            <br><br>
            <strong>webin@kaarvifoods.com</strong>
            <br><br>
            <strong>Kaarvi Nutraceuticals & Foods Pvt. Ltd.</strong><br>
Gat No. 468, Kusgaon Khind Road, Tal: Bhor,<br>
Dist: Pune - 412205  Maharashtra, INDIA
        </p>
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>

<style>
.kaarvi-cntact-info {
    padding: 3rem;
}
.kaarvi-cntact-info h4 {
    font-size: 2rem;
    color: #01994F;
    margin-bottom: 30px;
}
</style>