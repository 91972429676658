<template>
  <div class="kaarvi-about-info">
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <div class="row kaarvi-about-container">
          <div class="col-lg-6 kaarvi-img">
            <img src="../assets/aboutus/our-story.png" alt="Our Story" />
          </div>
          <div class="col-lg-6">
            <h2>Our Story</h2>
            <p>
              We, <strong>Kaarvi Nutraceuticals & Foods Pvt Ltd,</strong> a
              young and dynamic venture formed to make eating real food as
              simple as it can be. As our tagline says, our goal is to provide
              <strong>'HEALTHY HARVEST'</strong>, to keep our consumers' life
              risk-free from ingesting harmful chemicals. <br /><br />
              We are manufacturer of Freeze-Dried Products Fruits, Vegetables,
              Herbs, Sprouts, Spices, Ready to Eat products, Milk and almost all
              types of food products. With our state of art production facility
              design, quality assurance and reliability you can trust us as your
              reliable supplier in this segment.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <div class="row kaarvi-about-container">
          <div class="col-lg-4"></div>
          <div class="col-lg-8">
            <div class="kaarvi-benefits">
              <div class="row">
                <div class="col-lg-4">
                  <img src="../assets/aboutus/farmlands.png" alt="Farm Land" />
                  <span>Farmlands</span>
                  <h3>19,900 SQFT</h3>
                </div>
                <div class="col-lg-4">
                  <img src="../assets/aboutus/factory.png" alt="Factory" />
                  <span>Factory</span>
                  <h3>4,000 SQFT</h3>
                </div>
                <div class="col-lg-4">
                  <img
                    src="../assets/aboutus/freezedryer.png"
                    alt="Freeze Dryer"
                  />
                  <span>Freeze Dryer</span>
                  <h3>400 KG</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-1"></div>
    </div>
    <div class="row">
      <div class="col-lg-1"></div>
      <div class="col-lg-10">
        <div class="row kaarvi-about-container">
          <div class="col-lg-6 kaarvi-img">
            <img
              src="../assets/aboutus/why-freeze-drying.png"
              alt="Why Freeze Drying"
            />
          </div>
          <div class="col-lg-6">
            <h2>Why Freeze Drying</h2>
            <p>
              With the ever increasing awareness for
              <strong>"preservatives and additives free"</strong> food products,
              Freeze Dried products are gaining huge acceptance and popularity
              in the market. <br /><br />
              Freeze drying technology is widely used in products such as
              seasonal vegetables, tropical fruit/juices (ripe/unripe),
              herbals/herbal extracts/aromatic herbs, spices, sprouts,
              flowers/flower petals, dairy products, and many more even in
              prepared whole meals.
            </p>
            
            <div class="kaarvi-drying-benefits">
              <h2>FREEZE-DRYING</h2>
              <h3>Key Benefits</h3>
              <img src="../assets//aboutus/circles.png" alt="Key Benefits" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-1"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUsInfo",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
.kaarvi-about-info {
  padding: 3rem 1rem 0;
}
.kaarvi-services-info > .row {
  margin: auto;
}
.kaarvi-benefits {
  background-color: #f15a22;
  padding: 1rem;
  border-radius: 10px;
}
.kaarvi-about-container {
  padding: 1rem 0;
  text-align: left;
  font-size: 0.85rem;
}
.kaarvi-about-container h2 {
  color: #f15a22;
  font-family: kaarviText;
  padding: 1rem 2rem;
  margin: 0;
}
.kaarvi-about-container p {
  padding-left: 2rem;
}
.kaarvi-benefits > div > div {
  text-align: center;
}
.kaarvi-benefits h3 {
  color: #fff;
  font-family: kaarviText;
  font-weight: bold;
}
.kaarvi-benefits span {
  color: #fff;
  margin: 1rem 0 0;
  display: block;
  font-size: 1rem;
}
.kaarvi-drying-benefits {
  margin: 8rem 0 0 2rem;
}
.kaarvi-drying-benefits h2 {
  font-size: 1rem;
  padding-left: 1rem;
}
.kaarvi-drying-benefits h3 {
  padding-left: 1rem;
}
.kaarvi-drying-benefits img {
  display: block;
  margin: 1rem;
}
@media screen and (max-width: 640px) {
  .kaarvi-drying-benefits {
    margin: 0;
  }
  .kaarvi-drying-benefits img {
    width: 100%;
    margin: 0;
  }
}
</style>
