<template>
    <div class="kaarvi-services-info">
        <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
                <p class="kaarvi-products-content">Freeze-dried products are foods or products that have undergone the freeze-drying process. This preservation method involves freezing the item and then removing the water content through sublimation, turning ice directly into vapor. The result is a lightweight, crispy, and flavourful product that retains its natural taste, colour, and nutrients. Freeze-dried products have a longer shelf life, are portable, and are used in various industries, such as food, pharmaceuticals, and aerospace. They are popular for their convenience, nutritional value, and versatility in culinary applications.</p>
                <div class="kaarvi-products-container">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="fruits-tab" data-bs-toggle="tab" data-bs-target="#fruits" type="button" role="tab" aria-controls="FRUITS" aria-selected="true">FRUITS</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="veggies-tab" data-bs-toggle="tab" data-bs-target="#veggies" type="button" role="tab" aria-controls="VEGGIES" aria-selected="false">VEGGIES</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="herbs-tab" data-bs-toggle="tab" data-bs-target="#herbs" type="button" role="tab" aria-controls="HERBS" aria-selected="false">HERBS</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="sprouts-tab" data-bs-toggle="tab" data-bs-target="#sprouts" type="button" role="tab" aria-controls="SPROUTS" aria-selected="false">SPROUTS</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="rtc-tab" data-bs-toggle="tab" data-bs-target="#rtc" type="button" role="tab" aria-controls="RTC" aria-selected="false">RTC/RTE</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="beverages-tab" data-bs-toggle="tab" data-bs-target="#beverages" type="button" role="tab" aria-controls="BEVERAGES" aria-selected="false">BEVERAGES</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="dairy-tab" data-bs-toggle="tab" data-bs-target="#dairy" type="button" role="tab" aria-controls="DAIRY" aria-selected="false">DAIRY</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="fruits" role="tabpanel" aria-labelledby="fruits-tab">
                            <p>Freeze-dried fruits are a nutritious and flavourful snack, preserving the natural taste and nutritional value of fruits. They have a long shelf life, are portable, and can be used in various culinary applications. Enjoy them as a healthy on-the-go option or in your favourite recipes!</p>
                            <ul class="list-group list-group-horizontal row">
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/fruits/Strawberry.png" @click="openSpec($event)" width="200" height="200" alt="Strawberry"/><p>Strawberry</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/fruits/Mango.png" @click="openSpec($event)"  width="200" height="200" alt="Mango"/><p>Mango</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/fruits/Mango_Cubes.png" @click="openSpec($event)"  width="200" height="200" alt="Mango"/><p>Mango Cubes</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/fruits/Jackfruit.png" @click="openSpec($event)"  width="200" height="200" alt="Jackfruit"/><p>Jackfruit</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/fruits/Jackfruit-slice.png" @click="openSpec($event)"  width="200" height="200" alt="Jackfruit"/><p>Jackfruit Slice</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/fruits/Raw_Mango_Slice.png" @click="openSpec($event)"  width="200" height="200" alt="Raw Mango"/><p>Raw Mango Slice</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                            </ul>
                            <p>We have handled many other Fruits:<br>
Pineapple, Custard Apple, Figs, Apples, Oranges, Jamun, Guava, Chiku (sapota), Dates, Banana, Grapes, Papaya<br><br>
Exotic fruits like Pear, Persimmon, Avocado, Kiwi, Blueberries, Dragon Fruit, and many more
</p>
                        </div>
                        <div class="tab-pane fade" id="veggies" role="tabpanel" aria-labelledby="veggies-tab">
                            <p>Freeze-dried vegetables are nutritious and lightweight snacks or ingredients. The process involves freezing the vegetables and then removing water through sublimation, preserving their natural flavours and nutrients. They have a longer shelf life than fresh vegetables and are easy to store and carry. Freeze-dried vegetables can be enjoyed as healthy snacks or used in various dishes, providing convenience and a burst of natural flavours in every bite.</p>
                            <ul class="list-group list-group-horizontal row">
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Baby_Corn_Slice.png" @click="openSpec($event)"  width="200" height="200" alt="Baby Corn"/><p>Baby Corn</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Carrot.png" @click="openSpec($event)"  width="200" height="200" alt="Carrot"/><p>Carrot</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Cauliflower.png" @click="openSpec($event)"  width="200" height="200" alt="Cauliflower"/><p>Cauliflower</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Green_Zucchini_Cube.png" @click="openSpec($event)"  width="200" height="200" alt="Green Zucchini"/><p>Green Zucchini</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Green_Zucchini_Slice.png" @click="openSpec($event)"  width="200" height="200" alt="Green Zucchini"/><p>Green Zucchini</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Greenpeas.png" @click="openSpec($event)"  width="200" height="200" alt="Greenpeas"/><p>Greenpeas</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Onion.png" @click="openSpec($event)"  width="200" height="200" alt="Onion"/><p>Onion</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Parsly.png" @click="openSpec($event)"  width="200" height="200" alt="Parsley"/><p>Parsley</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Red_Bell_Pepper.png" @click="openSpec($event)"  width="200" height="200" alt="Red Bell Pepper"/><p>Red Bell Pepper</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Sweet_corn.png" @click="openSpec($event)"  width="200" height="200" alt="Sweet Corn"/><p>Sweet Corn</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/veggies/Yello_Zucchini_Slice.png" @click="openSpec($event)"  width="200" height="200" alt="Yellow Zucchini"/><p>Yellow Zucchini</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                            </ul>
                            <p>Other Vegetables: <br>
Spinach, Coriender, Pumpkins, Beetroot, Potato, Tomatoes, cabbage, garlic, French beans, local beans, spring onions<br><br>
Exotic vegetables: Mushroom, Broccoli, Leek, Kale, and many more
</p>
                        </div>
                        <div class="tab-pane fade" id="herbs" role="tabpanel" aria-labelledby="herbs-tab">
                            <p>Freeze-dried herbs are a convenient and flavourful alternative to fresh herbs. Freeze Drying process preserves their aroma, taste, and nutrients. These lightweight and shelf-stable herbs have a longer lifespan than fresh ones and can be easily added to dishes for a burst of natural flavours. Whether used in cooking, baking, or as a garnish, freeze-dried herbs offer a hassle-free way to enhance your culinary creations.</p>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/herbs/Turmeric.png" @click="openSpec($event)"  width="200" height="200" alt="Turmeric"/><p>Turmeric</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                            </ul>
                            <p>Other Herbs:<br> Gooseberry (Awala), Mint, Tulsi, roots, ginger, rosemary, chives, flower petals
</p>
                        </div>
                        <div class="tab-pane fade" id="sprouts" role="tabpanel" aria-labelledby="sprouts-tab">
                            <p>Freeze-dried sprouts are a nutritious and crunchy option for adding to your diet. You can easily incorporate freeze-dried sprouts into salads, sandwiches, or snacks, providing a convenient and healthy way to enhance the nutritional value of your meals </p>
                            <p>All kinds of sprouts like Moth beans sprout, chic peas sprouts, Moong beans sprouts</p>
                        </div>
                        <div class="tab-pane fade" id="rtc" role="tabpanel" aria-labelledby="rtc-tab">
                            <p>Freeze-dried RTE (Ready-to-Eat) and RTC (Ready-to-Cook) products are convenient and versatile food options. Freeze-dried RTE products are pre-cooked and can be consumed without further preparation, making them ideal for quick and on-the-go meals. On the other hand, RTC products require simple rehydration or cooking before consumption, providing a time-saving solution for home-cooked meals. Both options offer long shelf lives, portability, and ease of use, making them popular choices for busy individuals and outdoor enthusiasts.</p>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/rtc-rte/Brown_Gravy.png" @click="openSpec($event)"  width="200" height="200" alt="Brown Gravy"/><p>Brown Gravy</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/rtc-rte/Jain_Red_Gravy.png" @click="openSpec($event)"  width="200" height="200" alt="Jain Red Gravy"/><p>Jain Red Gravy</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                            </ul>
                            <p>Paneer Butter Masala, Akhha Masoora, Pavbhaji, Poha, Upma, Sambhar, Noodles, all types of Rice Pulav, Biryanis, Dal Chaval, and all type of gravies</p>
                        </div>
                        <div class="tab-pane fade" id="beverages" role="tabpanel" aria-labelledby="beverages-tab">
                            <p>Freeze-dried beverages are powdered drink mixes created through the freeze-drying process. The process involves freezing the liquid beverage and then removing the water content through sublimation, leaving behind a dry and lightweight powder. These freeze-dried beverage powders can be easily rehydrated with water to recreate the original drink. They are popular for their long shelf life, portability, and convenience, making them an ideal choice for travellers, hikers, and people on the go. Freeze-dried beverages come in various flavours, including coffee, tea, fruit juices, and many more, providing a quick and easy way to enjoy your favourite drinks anytime, anywhere.</p>
                            <p>Masala Tea, Cardamom Tea, Lemongrass Tea, Black Tea, Black Coffee, Coffee with milk & Sugar, Sugar free Tea & Coffee, Fruit Juices, Shakes, Smoothies</p>
                        </div>
                        <div class="tab-pane fade" id="dairy" role="tabpanel" aria-labelledby="dairy-tab">
                            <p>Freeze-dried dairy products are a convenient and versatile option for preserving dairy items. These freeze-dried dairy products, such as milk, cheese, yogurt and even dairy cultures retain their original flavours, nutrients, and texture. They have an extended shelf life compared to fresh dairy and do not require refrigeration, making them ideal for long-term storage and outdoor adventures. When needed, simply add water to rehydrate the freeze-dried dairy products, allowing for easy use in various recipes and as standalone snacks.</p>
                            <ul class="list-group list-group-horizontal">
                                <li class="list-group-item col-md-3">
                                    <img src="../assets/products/dairy/Dairy.png" @click="openSpec($event)"  width="200" height="200" alt="Milk"/><p>Milk</p>
                                    <img src="../assets/products/leaf-icon.png" class="kaarvi-corner"/>
                                </li>
                            </ul>
                            <p>Other products: <br>
                                Curd powder, Milk shakes, Colostrum milk powder, Lassi, flavored dairy products </p>
                        </div>
                    </div>
                    <div v-if="showSpec" class="bootstrap-modal-no-jquery">
                        <div class="modal" tabindex="-1" role="dialog">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">{{ currentProduct.name }}</h5>
                                        <button type="button" class="close" data-dismiss="modal" @click="closeSpecs">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <table>
                                            <tr>
                                                <td>Available in</td>
                                                <td>{{ currentProduct.Available_in }}</td>
                                            </tr>
                                            <tr>
                                                <td>Moisture</td>
                                                <td>{{ currentProduct.Moisture }}</td>
                                            </tr>
                                            <tr>
                                                <td>Cutting Size</td>
                                                <td>{{ currentProduct.Cutting_Size }}</td>
                                            </tr>
                                            <tr>
                                                <td>Availability</td>
                                                <td>{{ currentProduct.Availability }}</td>
                                            </tr>
                                            <tr>
                                                <td>Packing</td>
                                                <td>{{ currentProduct.Packing }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-1"></div>
        </div>
    </div>
</template>
<script>
export default {
  name: "ProductsInfo",
  data() {
    return {
        showSpec: false,
        specs: [
            {
                'name': 'Strawberry',
                'Available_in': 'Slices, Chunks/Bits, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Mango',
                'Available_in': 'Slices, Chunks/Bits, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Jackfruit',
                'Available_in': 'Slices,Chips, Chunks/Bits, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '8mm',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Raw Mango',
                'Available_in': 'Slices, Chunks/Bits forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Seasonal',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Baby Corn',
                'Available_in': 'Slices, Chunks/Bits, Powder form',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Seasonal',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Carrot',
                'Available_in': 'Slices, cubes, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Cauliflower',
                'Available_in': 'Slices, Chunks/Bits, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Green Zucchini',
                'Available_in': 'Slices, Chunks/Bits, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Seasonal',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Greenpeas',
                'Available_in': 'Whole, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '-',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Onion',
                'Available_in': 'Slices, Chunks/Bits, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Parsley',
                'Available_in': 'Leafy, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '8mm/10mm',
                'Availability': 'Seasonal',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Red Bell Pepper',
                'Available_in': 'Slices, Chunks/Bits, Powder form',
                'Moisture': '< 5%',
                'Cutting_Size': '8mm/10mm',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Sweet Corn',
                'Available_in': 'Whole, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '-',
                'Availability': 'Round the year',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Yellow Zucchini',
                'Available_in': 'Slices, Chunks/Bits, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Seasonal',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Turmeric',
                'Available_in': 'Slices, Chunks/Bits, Grated, Powder forms',
                'Moisture': '< 5%',
                'Cutting_Size': '5mm/8mm/10mm',
                'Availability': 'Seasonal',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Brown Gravy',
                'Available_in': 'Natural granules or Powder form ',
                'Moisture': '< 5%',
                'Cutting_Size': '-',
                'Availability': 'Order to make basis',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Jain Red Gravy',
                'Available_in': 'Natural granules or Powder form',
                'Moisture': '< 5%',
                'Cutting_Size': '-',
                'Availability': 'Order to make basis',
                'Packing': '3-Layer Aluminium – Food Grade',
            },
            {
                'name': 'Milk',
                'Available_in': 'Natural granules or Powder form',
                'Moisture': '< 5%',
                'Cutting_Size': '-',
                'Availability': 'Order to make basis',
                'Packing': '3-Layer Aluminium – Food Grade',
            }
        ],
        currentProduct: {}
    }
  },
  methods: {
    openSpec(e) {
        this.showSpec = true;
        console.log(e.target.alt);
        this.currentProduct = this.specs.find((element) => element.name === e.target.alt);
        console.log(this.currentProduct.Available_in);
    },
    closeSpecs() {
        this.showSpec = false;
    }
  }
};
</script>
<style scoped>
.kaarvi-products-content, .tab-pane > p {
    margin: 20px;
    text-align: left;
}
.nav-tabs .nav-link {
    color: #77787B;
    font-weight: 700;
}
.nav-tabs .nav-link.active {
    color: #F15A22;
}
.tab-content ul li {
    position: relative;
    cursor: pointer;
}
.kaarvi-products-container {
    position: relative;
}
.tab-content ul li img + p {
    display: none;
    font-family: kaarviText;
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    position: absolute;
    top: calc(50% - 16px);
    width: calc(100% - 40px);
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
}
.tab-content ul li:hover img {
    transform: scale(1.1);
    transition-duration: 1s;
}
.tab-content ul li:hover img + p {
    display: block;
}
li.list-group-item.col-md-3 {
    border: 1px solid #ccc !important;
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    padding: 20px;
    background-color: #E2E2E2;
}
.kaarvi-corner {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 31px;
}
.modal-dialog {
    width: 400px;
    height: 300px;
}
.modal-content table td {
    border: 1px solid #ccc;
    padding: 5px;
}
@media screen and (max-width: 640px) {
    /* img {
        width: 100% !important;
    } */
}
</style>
