<template>
    <AppHeader/>
    <ServicesBanner/>
    <ServiceInfo/>
    <ContactForm/>
    <AppFooter/>
  </template>
  
  <script>
  import AppHeader from './Common/AppHeader.vue';
  import ServicesBanner from './ServicesBanner.vue';
  import ServiceInfo from './ServiceInfo.vue';
  import ContactForm from './Common/ContactForm.vue';
  import AppFooter from './Common/AppFooter.vue';
  
  export default {
    name: 'ServicesPage',
    components: {
      AppHeader,
      ServicesBanner,
      ServiceInfo,
      ContactForm,
      AppFooter
    }
  }
  </script>
  
  <style>
  </style>
  