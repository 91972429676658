<template>
    <div class="kaarvi-drying">
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <h2>Freeze Drying Process</h2>
                <div class="row justify-content-center kaarvi-drying-container">
                    <div class="col-lg-4 kaarvi-steps-left">
                        <div class="kaarvi-steps row">
                            <div class="col-lg-2 hide-mobile">&nbsp;</div>
                            <div class="text col-lg-8 col-sm-6">
                                <h4>Fresh Harvest</h4>
                                <p>Sourcing high-quality fruits and vegetables from a trusted network of farmers and traders</p>
                            </div>
                            <div class="col-lg-2 col-sm-6"><img src="../assets/products/process1.png" alt=""/></div>
                        </div>
                        <div class="kaarvi-steps row">
                            <div class="text col-lg-8 col-sm-6">
                                <h4>Storage</h4>
                                <p>Cleaning, sorting, and storing the product hygienically in the cold room before pre-processing</p>
                            </div>
                            <div class="col-lg-2 col-sm-6"><img src="../assets/products/process2.png" alt=""/></div>
                            <div class="col-lg-2 hide-mobile">&nbsp;</div>
                        </div>
                        <div class="kaarvi-steps row">
                            <div class="col-lg-2 hide-mobile">&nbsp;</div>
                            <div class="text col-lg-7 col-sm-6">
                                <h4>Pre-Processing</h4>
                                <p>Raw materials are taken out in batches for pre-processing, where operations such as peeling, cutting, or crushing are carried out</p>
                            </div>
                            <div class="col-lg-3 col-sm-6"><img src="../assets/products/process3.png" alt=""/></div>
                        </div>
                    </div>
                    <div class="col-lg-3 kaarvi-strawberry hide-mobile">
                        <img src="../assets/products/Strawberry.png" alt="Strawberry" width="300" />
                    </div>
                    <div class="col-lg-4 kaarvi-steps-right">
                        <div class="kaarvi-steps row">
                            <div class="col-lg-3 col-sm-6"><img src="../assets/products/process4.png" alt=""/></div>
                            <div class="text col-lg-8 col-sm-6">
                                <h4>Loading</h4>
                                <p>The pre-processed material is arranged in uniform trays and placed in a blast freezer, where it is frozen to temperatures below -35°C.</p>
                            </div>
                            <div class="col-lg-1 hide-mobile">&nbsp;</div>
                        </div>
                        <div class="kaarvi-steps row">
                            <div class="col-lg-1 hide-mobile">&nbsp;</div>
                            <div class="col-lg-3 col-sm-6"><img src="../assets/products/process5.png" alt=""/></div>
                            <div class="text col-lg-8 col-sm-6">
                                <h4>Drying</h4>
                                <p>The trays containing the material are transferred to the drying chamber, where the frozen material is exposed to a vacuum to extract the water in the form of vapor.</p>
                            </div>
                        </div>
                        <div class="kaarvi-steps row">
                            <div class="col-lg-3 col-sm-6"><img src="../assets/products/process6.png" alt=""/></div>
                            <div class="text col-lg-8 col-sm-6">
                                <h4>Unloading & Packing</h4>
                                <p>Once the drying process is complete, the material is removed from the vacuum chamber and transferred to aluminum foil bags. This packing procedure takes place in a controlled humidity room.</p>
                            </div>
                            <div class="col-lg-1 hide-mobile">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DryingProcess'
}
</script>
<style scoped>
.kaarvi-drying {
    padding: 3.5rem;
    background-color: #FFDDE9;
}
.kaarvi-drying h2 {
    padding: 15px 0;
    color: #77787B;
}
@media screen and (min-width: 640px) {
    .kaarvi-steps {
        padding: .5rem 0;
        height: 120px;
    }
}
.kaarvi-strawberry {
    padding: 1rem;
    cursor: pointer;
}
.kaarvi-steps-left .text {
    text-align: right
}
.kaarvi-steps-right .text {
    text-align: left;
}
.kaarvi-steps .text h4 {
    font-size: 1.1rem;
    color: #77787B;
}
.kaarvi-steps .text p {
    font-size: .7rem;
    color: #77787B;
}
.kaarvi-steps img {
    width: 70px;
    cursor: pointer;
}
.kaarvi-steps img:hover {
    transform: rotate(-25deg);
    transition-duration: 1s;
}
.kaarvi-steps-right img:hover {
    transform: rotate(25deg);
    transition-duration: 1s;
}
.kaarvi-strawberry img:hover {
    transform: scale(1.1); 
    transition-duration: 0.5s;
}
@keyframes rotateImage {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(25deg);}
}

@media screen and (max-width: 640px) {
    .kaarvi-drying {
        padding: 1rem;
    }
    .kaarvi-steps-left .text, .kaarvi-steps-right .text {
        text-align: center;
    }
    .hide-mobile {
        display: none;
    }
    .kaarvi-steps {
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        height: auto;
    }
    .kaarvi-steps > div {
        width: 50%;
        float: left;
    }
}
</style>
